<template>
  <base-section id="k-d-a-project-header" space="0">
    <base-img :src="require('@/assets/bg/bg-project-header.png')">
      <v-container fluid class="fill-height pa-0">
        <v-row :style="m_objContainer" no-gutters>
          <v-col cols="auto">
            <p
              :class="`${
                g_bLowerBr ? 'kda-ts-36pt mt-2' : 'kda-ts-80pt'
              } wt-extrabold ln-160 white--text`"
              style="max-width: 1038px"
            >
              {{ m_strContent }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </base-img>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';

export default {
  name: 'KDAProjectHeader',

  mixins: [BusinessDetails, BaseBreakpoint],

  computed: {
    m_strContent() {
      return this.$vuetify.lang.t('$vuetify.kda.project.header');
    },

    m_objContainer() {
      let paddingTop = '0px';
      let paddingBottom = '0px';
      let paddingX = '72px';

      if (this.g_bLowerBr) {
        paddingTop = '124px';
        paddingBottom = paddingTop;
        paddingX = '32px';
      }

      return { padding: `${paddingTop} ${paddingX} ${paddingBottom}` };
    }
  }
};
</script>
